import '../components/Hero.css';

function Hero() {
    return (
        <div className='hero'>
            <div className="container mx-auto px-4 sm:px-0">
                <h1>Calgary's Premier Roofing Solutions</h1>
                <p>Secure, Enhance, and Transform Your Space with the Finest all-season Roofing Services in Calgary - Unleashing a Legacy of Craftsmanship and Reliability</p>
                <a href='#contact'>Get a Free Quote</a>
            </div>
        </div>
    );
}

export default Hero;