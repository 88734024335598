import "../components/Footer.css";
import facebookIcon from "../assets/icons/facebook.svg";
import instagramIcon from "../assets/icons/instagram.svg";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function Footer() {
    return (
        <div className="footer">
            <div className="container mx-auto px-4 sm:px-0">
                <div className="footer__content">
                    <div className="footer__column footer__column--left">

                        {/* TODO: Add headers for sections */}

                        <p>Contact:</p>
                        <a href="tel:+14033990207">+1 (403) 399-0207</a>
                        <a href="mailto: smtroofingyyc@gmail.com">smtroofingyyc@gmail.com</a>
                        <div className="footer__media">
                            <a href="https://www.facebook.com">
                                <img
                                    src={facebookIcon}
                                    alt="SMT Roofing Inc's facebook page"
                                ></img>
                            </a>
                            <a href="https://www.instagram.com">
                                <img
                                    src={instagramIcon}
                                    alt="SMT Roofing Inc's instagram"
                                ></img>
                            </a>
                        </div>
                    </div>
                    <div className="footer__column footer__column--double">
                        <div className="footer__column">
                            <Link to={"../privacy"}>Privacy Policy</Link>
                        </div>
                        <div className="footer__column">
                            <HashLink to={"../#about"}>About us</HashLink>
                            <HashLink to={"../#portfolio"}>Gallery</HashLink>
                            <HashLink to={"../#reviews"}>Reviews</HashLink>
                            <Link to={"../materials"}>Materials</Link>
                        </div>
                    </div>
                </div>


                <div className="footer__copyright">
                    <p>© 2024 SMT Roofing Inc. All rights reserved.</p>
                    <a href="https://buz-web-solutions-void.pages.dev">
                        Created by: <span>Buz Web Solutions</span>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Footer;
