import React, { useEffect, useState } from "react";
import "../components/Portfolio.css";

import imageSlider1 from "../assets/img/carousel/1.jpeg";
import imageSlider2 from "../assets/img/carousel/2.jpeg";
import imageSlider3 from "../assets/img/carousel/3.jpeg";
import imageSlider4 from "../assets/img/carousel/4.jpeg";
import imageSlider5 from "../assets/img/carousel/5.jpeg";
import imageSlider6 from "../assets/img/carousel/6.jpeg";

function Portfolio() {
    const [marginStyleLeft, setMarginStyleLeft] = useState({});
    const [marginStyleRight, setMarginStyleRight] = useState({});

    useEffect(() => {
        setTimeout(() => {
            // Get the computed styles of the .container element
            const containerElement = document.querySelector(".container");
            const containerStyles = window.getComputedStyle(containerElement);
    
            // Extract the margin-left property
            const marginLeft = containerStyles.getPropertyValue("margin-left");
            const marginRight = containerStyles.getPropertyValue("margin-right");
            console.log(marginLeft);
    
            // Set the marginStyle state with the extracted margin-left property
            setMarginStyleLeft({ marginLeft });
            setMarginStyleRight({ marginRight });
        }, 1000);
    }, []);

    return (
        <>
            <div className="container mx-auto px-4 sm:px-0" id='portfolio'>
                <h4 id="portfolio__title">
                    Discover Our <span>Roofing Projects</span>
                </h4>
            </div>
            <div className="portfolio" style={marginStyleLeft}>
                <div className="portolio__slider">
                    <div className="portfolio__slide">
                        <img src={imageSlider1} alt="Calgary Roofing Projects Showcase"></img>
                    </div>
                    <div className="portfolio__slide">
                        <img src={imageSlider2} alt="Craftsmanship in Calgary Roof Installations"></img>
                    </div>
                    <div className="portfolio__slide">
                        <img src={imageSlider3} alt="Roofing Excellence: Calgary Portfolio"></img>
                    </div>
                    <div className="portfolio__slide">
                        <img src={imageSlider4} alt="Calgary's Premier Roofing Installations"></img>
                    </div>
                    <div className="portfolio__slide">
                        <img src={imageSlider5} alt="Quality Roofing Work in Calgary Homes"></img>
                    </div>
                    <div className="portfolio__slide">
                        <img src={imageSlider6} style={marginStyleRight} alt="Discover Our Calgary Roofing Success Stories"></img>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Portfolio;
