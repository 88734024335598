import "../components/Nav.css";
import React, { useState, useEffect } from "react";
import Logo from "../assets/logo.svg";
import menuIcon from "../assets/icons/list.svg";
import { Link } from "react-router-dom";
import Materials from "../pages/Materials";
import { HashLink } from "react-router-hash-link";

function Nav() {
    const [scrollY, setScrollY] = useState(0);
    const [scrollingDown, setScrollingDown] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            // Check if the user is scrolling down or up
            setScrollingDown(currentScrollY > scrollY);
            setScrollY(currentScrollY);
        };

        // Add scroll event listener
        window.addEventListener("scroll", handleScroll);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [scrollY]);

    useEffect(() => {
        // Add or remove the class based on the scrolling direction
        const nav = document.getElementById("navbar");

        if (scrollingDown && scrollY > 100) {
            nav.classList.add("nav--down");
        } else {
            nav.classList.remove("nav--down");
        }
    }, [scrollingDown, scrollY]);

    const [mobileMenu, setMobileMenu] = useState(false);

    function toggleMobileMenu() {
        if (mobileMenu) {
            setMobileMenu(false);
            console.log("false");
        } else {
            setMobileMenu(true);
            console.log("true");
        }
    }

    const closeMobileMenu = () => {
        setMobileMenu(false);
    };

    return (
        <nav id="navbar" className="nav">
            <div className="container mx-auto px-4 sm:px-0">
                <div className="nav__logo">
                    <Link to={"../"} aria-label="SMT Roofing Home page">
                        <img src={ Logo } alt="SMT Roofing Inc. – Roofing services in Calgary"></img>
                    </Link>
                </div>
                <ul className="nav__list">
                    <li>
                        <HashLink to={"../#about"}>About Us</HashLink>
                    </li>
                    <li>
                        <HashLink to={"../#portfolio"}>Projects</HashLink>
                    </li>
                    <li>
                        <HashLink to={"../#reviews"}>Testimotionals</HashLink>
                    </li>
                    <li>
                        <Link to={"../materials"}>Materials</Link>
                    </li>
                </ul>
                <a href="#contact">
                    <HashLink to={"../#contact"}>
                        <button className="nav__button" type="button">
                            Get A Quote
                        </button>
                    </HashLink>
                </a>
                <button className="mobile-only" onClick={toggleMobileMenu}>
                    <img src={menuIcon} alt="Menu of SMT Roofing website"></img>
                </button>
            </div>
            <MobileMenu
                mobileStatus={mobileMenu}
                closeMobileMenu={closeMobileMenu}
            />
        </nav>
    );
}

function MobileMenu({ mobileStatus, closeMobileMenu }) {
    const handleMenuItemClick = () => {
        closeMobileMenu();
    };

    if (mobileStatus) {
        return (
            <div className="mobile-menu">
                <ul>
                    <li onClick={handleMenuItemClick}>
                        <HashLink to={"../#about"}>About Us</HashLink>
                    </li>
                    <li onClick={handleMenuItemClick}>
                        <HashLink to={"../#portfolio"}>Projects</HashLink>
                    </li>
                    <li onClick={handleMenuItemClick}>
                        <HashLink to={"../#reviews"}>Testimotionals</HashLink>
                    </li>
                    <li onClick={handleMenuItemClick}>
                        <Link to={"../materials"}>Materials</Link>
                    </li>
                </ul>
            </div>
        );
    }
}

export default Nav;
