import "../components/Reviews.css";
import reviewPicture from "../assets/img/review.jpeg";
import reviewIcon from "../assets/icons/star.svg";

function Reviews() {
    return (
        <div className="reviews" id="reviews">
            <div className="container mx-auto px-4 sm:px-0">
                <h4>
                    What Our <span>Clients</span> Say
                </h4>
                <div className="reviews__content">
                    <div className="reviews__content__elements">
                        <div className="reviews__content-element">
                            <div className="reviews__stars">
                                <img
                                    src={reviewIcon}
                                    alt="SMT Roofing Inc. gets 5 star reviews"
                                ></img>
                                <img
                                    src={reviewIcon}
                                    alt="SMT Roofing Inc. gets 5 star reviews"
                                ></img>
                                <img
                                    src={reviewIcon}
                                    alt="SMT Roofing Inc. gets 5 star reviews"
                                ></img>
                                <img
                                    src={reviewIcon}
                                    alt="SMT Roofing Inc. gets 5 star reviews"
                                ></img>
                                <img
                                    src={reviewIcon}
                                    alt="SMT Roofing Inc. gets 5 star reviews"
                                ></img>
                            </div>
                            <p>
                                "I recently hired SMT Roofing, and I'm thrilled
                                with the results. From start to finish, their
                                team was professional and efficient. The new
                                roof looks fantastic, and the crew maintained a
                                clean work area throughout. Highly recommend!"
                            </p>
                        </div>
                        <div className="reviews__content-element">
                            <div className="reviews__stars">
                                <img
                                    src={reviewIcon}
                                    alt="SMT Roofing Inc. gets 5 star reviews"
                                ></img>
                                <img
                                    src={reviewIcon}
                                    alt="SMT Roofing Inc. gets 5 star reviews"
                                ></img>
                                <img
                                    src={reviewIcon}
                                    alt="SMT Roofing Inc. gets 5 star reviews"
                                ></img>
                                <img
                                    src={reviewIcon}
                                    alt="SMT Roofing Inc. gets 5 star reviews"
                                ></img>
                                <img
                                    src={reviewIcon}
                                    alt="SMT Roofing Inc. gets 5 star reviews"
                                ></img>
                            </div>
                            <p>
                                "SMT Roofing exceeded our expectations. They
                                provided a clear estimate, worked efficiently,
                                and delivered a high-quality roof. Trustworthy
                                and skilled — highly recommended for roofing
                                needs."
                            </p>
                        </div>
                    </div>
                    <img
                        src={reviewPicture}
                        alt="Successfully completed roofing project in Calgary by XYZ Roofing"
                    ></img>
                </div>
                <div className="decorCircle1"></div>
                <div className="decorCircle2"></div>
            </div>
        </div>
    );
}

export default Reviews;
