import React from "react";
import "../components/Contact.css";
import emailIcon from "../assets/icons/email.svg";
import phoneIcon from "../assets/icons/phone.svg";
import pinIcon from "../assets/icons/pin.svg";
import { Link } from "react-router-dom";

function ContactTitle() {
    return (
        <div className="contact__title">
            <h4>
                Get in Touch for <span>Your</span> Roofing Needs
            </h4>
            <p>
                While we can provide an initial estimate based on your home's
                square footage, please note that the final, accurate pricing
                will be determined after an on-site assessment. Schedule a
                visit, and let's discuss your roofing needs in detail. Your
                home's unique features and our expert evaluation will ensure a
                precise, customized quote tailored just for you.
            </p>
        </div>
    );
}

function ContactForm() {
    const [submited, setSubmited] = React.useState(false);

    const onSubmit = async (event) => {
        event.preventDefault();
        setSubmited(true);
        const formData = new FormData(event.target);

        formData.append("access_key", "3cb51d25-8a09-4bfa-8192-fea1faa72307");

        await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            body: formData,
        });
    };

    if (!submited) {
        return (
            <div>
                <ContactTitle />
                <div className="contact__form px-4">
                    <form onSubmit={onSubmit}>
                        <label for="name">Name</label>
                        <input
                            id="name"
                            name="name"
                            type="text"
                            required
                        ></input>
                        <label for="email">E-mail</label>
                        <input
                            id="email"
                            name="email"
                            type="email"
                            required
                        ></input>
                        <label for="brief">Describe your case</label>
                        <textarea
                            id="brief"
                            name="brief"
                            type="textarea"
                            required
                        ></textarea>
                        <div className="contact__terms">
                            <label for="terms" className="contact__terms-label">
                                I accept the{" "}
                                <Link to={"./privacy"}>Privacy</Link>
                            </label>
                            <input
                                id="terms"
                                name="terms"
                                type="checkbox"
                                required
                            ></input>
                        </div>
                        <input
                            id="submit"
                            name="submit"
                            type="submit"
                            value="Submit"
                        ></input>
                    </form>
                </div>
            </div>
        );
    } else {
        return (
            <div className="contact__form--submited">
                <h4>Thank you for contacting us!</h4>
                <p>We will get in touch with you soon.</p>
            </div>
        );
    }
}

function ContactElement({ icon, header, paragraph, link, linkText }) {
    return (
        <div className="contact__contacts-element">
            <img src={icon} alt={header}></img>
            <h4>{header}</h4>
            <p>{paragraph}</p>
            <a href={link}>{linkText}</a>
        </div>
    );
}

function ContactContacts() {
    return (
        <div className="contact__contacts container px-4 sm:px-0">
            <ContactElement
                icon={emailIcon}
                header="Email"
                paragraph="Facing roofing issues? We're here to help! Click the button below to shoot us an email with your concerns. Don't forget to attach photos if you can – they'll help us assess the situation more accurately. Our team will get back to you promptly with expert advice and a personalized solution. Let's get your roof back in top shape!"
                link="mailto:smtroofingyyc@gmail.com"
                linkText="smtroofingyyc@gmail.com"
            />
            <ContactElement
                icon={phoneIcon}
                header="Phone"
                paragraph="For urgent matters that can't wait, reach out to us directly by phone. Our team is ready to tackle your roofing emergencies 24/7. Don't hesitate – call us now and let's address your roofing issues head-on. Your satisfaction and peace of mind are our top priorities. We're here for you whenever you need us."
                link="tel:+14033990207"
                linkText="+1 (403) 399-0207"
            />
        </div>
    );
}

function Contact() {
    return (
        <div className="contact" id="contact">
            <ContactForm />
            <ContactContacts />
            <div className="contact__decorCircle-1"></div>
            <div className="contact__decorCircle-2"></div>
            <div className="contact__decorCircle-3"></div>
        </div>
    );
}

export default Contact;
