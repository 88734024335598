import Nav from "../components/Nav";
import Article from "../components/Article";
import Footer from '../components/Footer';
import { useLayoutEffect } from "react";

function Materials() {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>
            <Nav />
            <div className="container mx-auto px-4 sm:px-0">
                <Article />
            </div>
            <Footer />
        </>
    );
}

export default Materials;
