import Nav from "../components/Nav";
import "../pages/Privacy.css";
import Footer from "../components/Footer";
import { useLayoutEffect } from "react";

function Privacy() {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    
    return (
        <>
            <Nav />
            <div className="privacy">
                <div className="container mx-auto px-4 sm:px-0">
                    <h1>Privacy Policy</h1>

                    <p>
                        This Privacy Policy applies to the{" "}
                        <strong>https://smt-roofing.com</strong> website
                        ("Website") operated by{" "}
                        <strong>SMT Roofing Inc.</strong> ("Company") for the{" "}
                        <strong>Roofing services</strong> product ("Product").
                        This Privacy Policy was last updated on{" "}
                        <strong>1/7/2024</strong>.
                    </p>

                    <h2>Information We Collect</h2>

                    <p>
                        We may collect certain personal information from users
                        of our Website and Product, including but not limited
                        to:
                    </p>

                    <ul>
                        <li>Full Name</li>
                        <li>Email Address</li>
                        <li>Phone Number</li>
                        <li>Address</li>
                        <li>Payment Information</li>
                    </ul>

                    <h2>Use of Information</h2>

                    <p>
                        The personal information we collect is used for the
                        following purposes:
                    </p>

                    <ul>
                        <li>
                            Providing and maintaining the Website and Product
                        </li>
                        <li>Improving and personalizing user experience</li>
                        <li>
                            Sending promotional materials or newsletters, if
                            opted-in
                        </li>
                        <li>
                            Contacting users regarding their inquiries or
                            requests
                        </li>
                    </ul>

                    <h2>Sharing of Information</h2>

                    <p>
                        We may share personal information with trusted third
                        parties for the purposes of:
                    </p>

                    <ul>
                        <li>Facilitating payment processing</li>
                        <li>Providing customer support services</li>
                        <li>Conducting data analysis and research</li>
                    </ul>

                    <h2>Security</h2>

                    <p>
                        We prioritize the security of your personal information
                        and take appropriate measures to protect it. However,
                        please note that no method of transmission over the
                        internet or electronic storage is 100% secure, and we
                        cannot guarantee its absolute security.
                    </p>

                    <h2>Changes to This Privacy Policy</h2>

                    <p>
                        We reserve the right to update or modify this Privacy
                        Policy at any time. Any changes made will be effective
                        immediately upon posting the updated Privacy Policy on
                        the Website.
                    </p>

                    <h2>Contact Us</h2>

                    <p>
                        If you have any questions or concerns about this Privacy
                        Policy, please contact us at <a href="mailto: smtroofingyyc@gmail.com"><strong>smtroofingyyc@gmail.com</strong>.</a>
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Privacy;
