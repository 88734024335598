import React from "react";
import { useLayoutEffect } from "react";

import Nav from "../components/Nav";
import Hero from "../components/Hero";
import Advantages from "../components/Advantages";
import About from "../components/About";
import Numbers from "../components/Numbers";
import Portfolio from "../components/Portfolio";
import Reviews from "../components/Reviews";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

function Home() {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    
    return (
        <>
            <Nav />
            <Hero />
            <Advantages />
            <About />
            <Numbers />
            <Portfolio />
            <Reviews />
            <Contact />
            <Footer />
        </>
    );
}

export default Home;