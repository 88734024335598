import "../components/About.css";
import aboutPicture from "../assets/img/about.jpeg";
import aboutPicture2 from "../assets/img/about2.jpeg";
import aboutPicture3 from "../assets/img/about3.jpeg";

function AboutElement({
    picture,
    altText,
    decoration,
    header,
    paragraph,
    leftImage = false,
    buttonText,
}) {
    return (
        <div
            className={
                leftImage
                    ? "about__element about__element--left"
                    : "about__element"
            }
        >
            <div className="about__picture">
                <img src={picture} alt={altText}>
                    {decoration}
                </img>
            </div>
            <div className="about__content">
                <h3>{header}</h3>
                <p>{paragraph}</p>
                <a className="about__button" href="#contact">
                    {buttonText}
                </a>
            </div>
        </div>
    );
}

function About() {
    return (
        <div className="about" id="about">
            <div className="container mx-auto px-4 sm:px-0">
                <AboutElement
                    picture={aboutPicture}
                    altText="Skilled Roofers Ensuring Quality Craftsmanship"
                    header="Trusted Roofing Experts in Calgary"
                    paragraph="At SMT Roofing, we take pride in the wealth of
                    experience our team brings to each roofing project. With
                    some of our dedicated professionals boasting up to 20
                    years in the field, our expertise is the cornerstone of
                    our success. We believe that the mastery acquired over
                    years translates into the quality and precision we
                    deliver to our clients."
                    buttonText="Hire Trusted Experts"
                />
                <AboutElement
                    picture={aboutPicture2}
                    altText="Skilled Roofers Ensuring Quality Craftsmanship"
                    header="Thousands of Roofs & Satisfied Customers"
                    paragraph="Before our company even had a name, our skilled
                    team was hard at work, contributing to the success of
                    thousands of roofing projects. The vast number of roofs
                    we've worked on reflects not only our longevity but also
                    the trust our clients place in us. Your satisfaction is
                    our driving force, and our track record speaks volumes
                    about the reliability and integrity embedded in our
                    services."
                    leftImage={true}
                    buttonText="Get a Free Quote"
                />
                <AboutElement
                    picture={aboutPicture3}
                    altText="Skilled Roofers Ensuring Quality Craftsmanship"
                    header="Quality Craftsmanship"
                    paragraph="At SMT Roofing, we go beyond the numbers; we
                    are defined by the quality of our work. Every nail,
                    every shingle, and every detail is handled with
                    precision and care. We take pride in our meticulous
                    approach, ensuring that even damaged shingles, with the
                    slightest scratches, are responsibly discarded. This
                    level of attention reflects our dedication to providing
                    you with a roof that not only meets industry standards
                    but exceeds your expectations in both durability and
                    aesthetics."
                    buttonText="Get Estimated Price"
                />
            </div>
        </div>
    );
}

export default About;
