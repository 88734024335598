import React, { useState, useEffect, useRef } from "react";
import "../components/About.css";

const useCounting = (
    initialCount,
    targetCount,
    step = 1,
    intervalDuration = 10
) => {
    const [count, setCount] = useState(initialCount);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (count < targetCount) {
                setCount((prevCount) => prevCount + step);
            } else {
                setCount(targetCount); // Ensure the count reaches the target exactly
                clearInterval(intervalId);
            }
        }, intervalDuration);

        return () => clearInterval(intervalId);
    }, [count, targetCount, intervalDuration]);

    return count;
};

const useInViewport = () => {
    const [inViewport, setInViewport] = useState(false);
    const elementRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    // Добавляем проверку, чтобы убедиться, что состояние изменяется только один раз
                    if (entry.isIntersecting && !inViewport) {
                        setInViewport(true);
                    }
                });
            },
            { threshold: 0.5 }
        );

        if (elementRef.current) {
            observer.observe(elementRef.current);
        }

        return () => {
            if (elementRef.current) {
                observer.unobserve(elementRef.current);
            }
        };
    }, [inViewport]); // Добавляем inViewport в зависимости, чтобы избежать предупреждения о зависимости

    return { inViewport, elementRef };
};

const Number = ({ number, duration, step, header, paragraph }) => {
    const { inViewport, elementRef } = useInViewport();
    const count = useCounting(0, inViewport ? number : 0, step, duration);

    // Check if counting has finished
    const countingFinished = count === number;

    return (
        <div className="numbers__element" ref={elementRef}>
            <p className="numbers__element-icon">
                {countingFinished ? `${count}+` : count}
            </p>
            <h4>{header}</h4>
            <p className="numbers__element-paragraph">{paragraph}</p>
        </div>
    );
};

function Numbers() {
    return (
        <div className="container mx-auto px-4 sm:px-0 about__numbers">
            <div className="numbers">
                <Number
                    number={20}
                    duration={50}
                    step={1}
                    header="Years of experience"
                    paragraph="Our team of skilled professionals brings years of expertise to every project."
                />
                <Number
                    number={100}
                    duration={2}
                    step={1}
                    header="Options for roof"
                    paragraph="We use top-notch materials to ensure the durability and reliability of your roof."
                />
                <Number
                    number={1000}
                    duration={1}
                    step={10}
                    header="Customers satisfied"
                    paragraph="Our commitment is to exceed customer expectations, ensuring satisfaction with every job."
                />
            </div>
        </div>
    );
}

export default Numbers;
