import "../components/Advantages.css";
import houseIcon from "../assets/icons/bi_house-down.svg";
import wrenchIcon from "../assets/icons/bi_wrench-adjustable.svg";
import glassesIcon from "../assets/icons/bi_eyeglasses.svg";
import flashIcon from "../assets/icons/bi_lightning-charge.svg";

function Advantage({ icon, alt, header, paragraph }) {
    return (
        <div className="advantages__element">
            <img src={icon} alt={header}></img>
            <h2>{header}</h2>
            <p>{paragraph}</p>
        </div>
    );
}

function Advantages() {
    return (
        <div className="container mx-auto px-4 sm:px-0">
            <div className="advantages">
                <Advantage
                    icon={houseIcon}
                    header="Shingle Roof Installation & Replacement"
                    paragraph="Professional installation of durable asphalt shingles for lasting protection and curb appeal."
                />
                <Advantage
                    icon={wrenchIcon}
                    header="Roof Repairs & Maintenance"
                    paragraph="Timely and reliable repairs to address any issues and prevent further damage."
                />
                <Advantage
                    icon={glassesIcon}
                    header="Inspections and Maintenance"
                    paragraph="Regular inspections and maintenance to extend the life of your roof."
                />
                <Advantage
                    icon={flashIcon}
                    header="Emergency & Storm Damage Repair"
                    paragraph="Quick response to unexpected roof emergencies to protect your home or business."
                />
            </div>
        </div>
    );
}

export default Advantages;
